import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';

import Layout from 'src/components/layout';
import ReadLink from 'src/components/post/preview/read-link';

import { MDXRenderer } from 'gatsby-plugin-mdx';

export const postDataQuery = graphql`
	query($slug: String) {
		mdx(frontmatter: { slug: { eq: $slug } }) {
			frontmatter {
				title
				author
			}
			body
		}
	}
`;

const PostTemplate = ({ data: { mdx: post } }) => (
	<Layout>
		<h1>{post.frontmatter.title}</h1>
		<p
			css={css`
				font-size: 0.75rem;
			`}
		>
			Posted by: {post.frontmatter.author}
		</p>

		<MDXRenderer>{post.body}</MDXRenderer>

		<ReadLink to="/">&larr; Back to the rest of the posts</ReadLink>
	</Layout>
);

export default PostTemplate;

// {JSON.stringify(post, null, 2)}
